import { useHistory } from 'react-router';

export const useNavToPlanPageAndOpenUpdatePaymentDetailsModal = (): (() => void) => {
	const history = useHistory();

	return () => {
		history.push({
			pathname: `plan`,
			search: '?updatePayment=true',
		});
	};
};
