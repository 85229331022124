import { ReactElement, FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { Column } from 'react-table';

import MobilePaginator from '@/components/ui/MobilePaginator';
import Table from '@/components/ui/Table';
import { useEligibilityUploads } from '@/hooks/api/useEligibility';
import { useIsMobile } from '@/hooks/layout/useIsMobile';
import { PreviousUploadResults } from '@/types/eligibility';
import { Partner } from '@/types/store/reducers';

import { MobileDataColumn, MobileLabel, MobileRow, MobileWrapper } from '../styles';
import messages from './messages';
import {
	FailureIcon,
	PendingIcon,
	SuccessIcon,
	TableColumn,
	TableWrapper,
	Title,
	TitleWrapper,
	BoldText,
} from './styles';

const PAGE_SIZE = 10;

interface Props {
	partner: Partner;
}

function NameCell({ value }: { value: string | null }): ReactElement {
	return <TableColumn>{value}</TableColumn>;
}

function StatusCell({ value, isMobile }: { value: string; isMobile?: boolean }): ReactElement {
	return (
		<TableColumn isMobile={isMobile}>
			{value === 'pending' ? (
				<PendingIcon data-testid="eligibility-upload-log-pending-icon" />
			) : value === 'failed' ? (
				<FailureIcon data-testid="eligibility-upload-log-failure-icon" />
			) : (
				<SuccessIcon data-testid="eligibility-upload-log-success-icon" />
			)}
		</TableColumn>
	);
}

function CreatedCell({ value }: { value: string }): ReactElement {
	const formattedDate = new Date(value).toLocaleString();
	return <TableColumn>{formattedDate.toLowerCase()} </TableColumn>;
}

function RowCell({ value }: { value: number | null }): ReactElement {
	return <TableColumn>{value === 0 ? '-' : value}</TableColumn>;
}

export function LogsTable({ partnerId }: { partnerId: string }): ReturnType<FC> {
	const { formatMessage } = useIntl();
	const [pageIndex, setPageIndex] = useState(0);
	const [isMobile] = useIsMobile();

	const { loading, data: eligibilityUploadData } = useEligibilityUploads({
		partnerId,
		pageIndex: pageIndex,
		pageSize: PAGE_SIZE,
	});

	function DescriptionCell({ row }: { row: { original: PreviousUploadResults } }): ReactElement {
		const { status, error } = row.original;

		switch (status) {
			case 'failed':
				if (error && error.length > 1) {
					const numberOfErrors = error.length;
					return <BoldText>{numberOfErrors} errors</BoldText>;
				}
				return <BoldText>{error && error[0].Msg}</BoldText>;
			case 'pending':
				return <TableColumn>{formatMessage(messages.processingMessage)}</TableColumn>;
			default:
				return <TableColumn>{formatMessage(messages.successfulUploadMessage)}</TableColumn>;
		}
	}

	const eligibilityUploads = eligibilityUploadData?.eligibility_uploads;
	const totalCount = eligibilityUploadData?.meta?.pagination.total ?? 0;

	const columns: Column<PreviousUploadResults>[] = [
		{
			Header: formatMessage(messages.dateHeader),
			accessor: 'processing_start_at',
			Cell: CreatedCell,
			minWidth: 210,
			width: '15%',
		},
		{
			Header: formatMessage(messages.statusHeader),
			accessor: 'status',
			Cell: StatusCell,
			width: '5%',
		},
		{
			Header: formatMessage(messages.fileNameHeader),
			accessor: 'name',
			Cell: NameCell,
			minWidth: 200,
			width: '15%',
		},
		{
			Header: formatMessage(messages.rowsHeader),
			accessor: 'users_total',
			Cell: RowCell,
			width: '12%',
		},
		{
			Header: formatMessage(messages.descriptionHeader),
			accessor: 'error',
			Cell: DescriptionCell,
		},
	];

	const pageCount = Math.max(1, Math.ceil(totalCount / PAGE_SIZE));
	const isOnlyOnePage = pageCount === 1;

	return (
		<TableWrapper isOnlyOnePage={isOnlyOnePage}>
			<TitleWrapper>
				<Title>
					{formatMessage(messages.title, {
						numberOfLogs: eligibilityUploads?.length ?? 0,
					})}
				</Title>
			</TitleWrapper>
			{isMobile ? (
				<>
					<MobileWrapper data-testid="mobile-eligibility-upload-logs-table">
						{eligibilityUploads?.map((log, index) => (
							<MobileRow key={index}>
								<MobileDataColumn>
									<MobileLabel>{formatMessage(messages.dateHeader)}</MobileLabel>
									<CreatedCell value={log.processing_start_at} />
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>{formatMessage(messages.statusHeader)}</MobileLabel>
									<StatusCell value={log.status} isMobile={isMobile} />
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>{formatMessage(messages.fileNameHeader)}</MobileLabel>
									<NameCell value={log.name} />
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>{formatMessage(messages.rowsHeader)}</MobileLabel>
									<RowCell value={log.users_total} />
								</MobileDataColumn>
								<MobileDataColumn>
									<MobileLabel>{formatMessage(messages.descriptionHeader)}</MobileLabel>
									<DescriptionCell row={{ original: log }} />
								</MobileDataColumn>
							</MobileRow>
						))}
					</MobileWrapper>
					<MobilePaginator
						fetchData={({ pageIndex: newPageIndex }: { pageIndex: number }): void =>
							setPageIndex(newPageIndex)
						}
						loading={loading}
						pageCount={pageCount}
						pageSize={PAGE_SIZE}
					/>
				</>
			) : (
				<>
					<Table
						dataTestId="eligibility-upload-logs-table"
						columns={columns}
						data={eligibilityUploads ?? []}
						fetchData={({ pageIndex: newPageIndex }: { pageIndex: number }): void =>
							setPageIndex(newPageIndex)
						}
						forcePageIndex={pageIndex}
						pageCount={pageCount}
						pageSize={PAGE_SIZE}
						loading={loading}
						alignHeadingsToText
						cellVerticalAlign="middle"
						customHeaderAndRowHeight={30}
					/>
				</>
			)}
		</TableWrapper>
	);
}

export default function EligibilityContent({ partner }: Props): ReactElement {
	return <LogsTable partnerId={partner.id} />;
}
