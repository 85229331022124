import { defineMessages } from 'react-intl';

export default defineMessages({
	title: {
		id: 'users.eligibility.logs.table.title',
		defaultMessage: '{numberOfLogs} Logs',
		description: 'The title of the table',
	},
	dateHeader: {
		id: 'users.eligibility.logs.table.dateHeader',
		defaultMessage: 'Date of upload',
		description: 'The title of the date column in the table',
	},
	statusHeader: {
		id: 'users.eligibility.logs.table.statusHeader',
		defaultMessage: 'Status',
		description: 'The title of the status column in the table',
	},
	fileNameHeader: {
		id: 'users.eligibility.logs.table.fileNameHeader',
		defaultMessage: 'File Name',
		description: 'The title of the file name column in the table',
	},
	rowsHeader: {
		id: 'users.eligibility.logs.table.rowsHeader',
		defaultMessage: 'Rows',
		description: 'The title of the rows column in the table',
	},
	descriptionHeader: {
		id: 'users.eligibility.logs.table.descriptionHeader',
		defaultMessage: 'Description',
		description: 'The title of the description column in the table',
	},
	processingMessage: {
		id: 'users.eligibility.logs.table.processingMessage',
		defaultMessage: 'Processing...',
		description: 'The message displayed when the logs are being processed',
	},
	successfulUploadMessage: {
		id: 'users.eligibility.logs.table.successfulUploadMessage',
		defaultMessage: 'Successful Upload',
		description: 'The message displayed when the logs uploaded successfully',
	},
});
