import copy from 'copy-to-clipboard';
import { FC, FormEvent, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { PrimaryButton, Loader, FormInput } from '@calm-web/design-system';
import useForm from '@calm-web/use-form';

import AddAdminModal from '@/components/pages/Account/AdminUsers/AddAdminModal';
import StickSaveButton from '@/components/pages/Account/StickySaveButton';
import CellTitle, { Subtitle } from '@/components/ui/CellTitle';
import { EnvConfig } from '@/env_config';
import { useEligibilityUploads } from '@/hooks/api/useEligibility';
import { useDefinedPartner } from '@/hooks/api/usePartner';
import {
	SftpConfig,
	useAddSftpEmailRecipients,
	useSftpConfig,
	useUploadSshKey,
} from '@/hooks/api/useSftpConfig';
import { EditSftpFormProps, useSftpForm, useSftpSubmitData } from '@/hooks/forms/useSftpForm';
import { setBannerMessage } from '@/store/actions/setBannerMessage';

import messages from './messages';
import { HelperLink, CardStyled, Row, FormInputStyled, SectionStyled, StyledTextArea } from './styles';

const { sftpUrl } = EnvConfig;
const SFTP_DOCS_LINK = '/docs/sftp-instructions';

const SftpAdminInfo: FC = () => {
	const { formatMessage } = useIntl();

	return (
		<Subtitle>
			{formatMessage(messages.sftpSubtitle, {
				linebreak: <br />,
				link: (...chunks: ReactNode[]) => (
					<HelperLink href={`${SFTP_DOCS_LINK}#eligibility-file`} target="_blank" rel="noopener noreferrer">
						{chunks}
					</HelperLink>
				),
			})}
		</Subtitle>
	);
};

const SftpAdmin: FC = () => {
	const { formatMessage } = useIntl();
	const [showAdminModal, setShowAdminModal] = useState(false);
	const partner = useDefinedPartner();

	return (
		<>
			<SectionStyled>
				<SftpAdminInfo />
			</SectionStyled>
			<SectionStyled>
				<PrimaryButton type="button" onPress={() => setShowAdminModal(true)}>
					{formatMessage(messages.itAdminEmailSubmit)}
				</PrimaryButton>
			</SectionStyled>
			<AddAdminModal
				partnerId={partner.id}
				showAdminModal={showAdminModal}
				setShowAdminModal={setShowAdminModal}
				isCalmHealth={false}
				isSftpAdmin
			/>
		</>
	);
};

const SshInfo: FC = () => {
	const { formatMessage } = useIntl();
	return (
		<Subtitle>
			{formatMessage(messages.sshKeyInfo, {
				linebreak: <br />,
				sshKeyLink: (...chunks: ReactNode[]) => (
					<HelperLink href={`${SFTP_DOCS_LINK}#ssh-key`} target="_blank" rel="noopener noreferrer">
						{chunks}
					</HelperLink>
				),
			})}
		</Subtitle>
	);
};

const SftpDetails: FC<{ username: string }> = ({ username }) => {
	const dispatch = useDispatch();
	const { formatMessage } = useIntl();
	const formProps = useForm('sftpDetailsForm', {
		initialModel: {
			username: username,
			sftpUrl,
		},
	});

	const copySftpUsername = (): void => {
		copy(username, {
			format: 'text/plain',
			onCopy: () => {
				dispatch(
					setBannerMessage({
						message: 'SFTP username copied to clipboard',
						isError: false,
						flash: true,
					}),
				);
			},
		});
	};

	const copySftpUrl = (): void => {
		copy(sftpUrl, {
			format: 'text/plain',
			onCopy: () => {
				dispatch(
					setBannerMessage({
						message: 'SFTP url copied to clipboard',
						isError: false,
						flash: true,
					}),
				);
			},
		});
	};

	const usernameProps = formProps.bindWithErrorProps('username');
	const sftpUrlProps = formProps.bindWithErrorProps('sftpUrl');

	const sftpUsernameId = 'sftp-username';
	const sftpUrlId = 'sftp-url';

	return (
		<SectionStyled>
			<Row>
				<div>
					<FormInputStyled
						{...usernameProps}
						disabled
						id={sftpUsernameId}
						data-testid="sftp-username"
						label={formatMessage(messages.sftpUsernameLabel)}
					/>
				</div>
				<div>
					<PrimaryButton onPress={copySftpUsername} aria-describedby={sftpUsernameId}>
						{formatMessage(messages.sftpUsernameCopyText)}
					</PrimaryButton>
				</div>
			</Row>
			<Row>
				<div>
					<FormInputStyled
						{...sftpUrlProps}
						disabled
						id={sftpUrlId}
						data-testid="sftp-url"
						label={formatMessage(messages.sftpUrlLabel)}
					/>
				</div>
				<div>
					<PrimaryButton onPress={copySftpUrl} aria-describedby={sftpUrlId}>
						{formatMessage(messages.sftpUrlCopyText)}
					</PrimaryButton>
				</div>
			</Row>
		</SectionStyled>
	);
};

const SshKey: FC<{ formProps: EditSftpFormProps; isConfigured: boolean }> = ({ formProps, isConfigured }) => {
	const { formatMessage } = useIntl();
	const sshKeyProps = formProps.bindWithErrorProps('ssh_key');

	return (
		<>
			<SectionStyled>
				<SshInfo />
			</SectionStyled>
			<SectionStyled>
				{
					<>
						<StyledTextArea
							{...sshKeyProps}
							placeholder={isConfigured ? '********' : undefined}
							data-testid="ssh-key-input"
							label={formatMessage(messages.sshKeyInput)}
						/>
					</>
				}
			</SectionStyled>
		</>
	);
};

const HelpSection: FC<{}> = () => {
	const { formatMessage } = useIntl();

	return (
		<SectionStyled>
			<Subtitle>
				{formatMessage(messages.helpText, {
					link: (...chunks: ReactNode[]) => (
						<HelperLink
							href="https://support.calm.com/hc/en-us?solvvyProvidedByEmployer"
							target="_blank"
							rel="noopener noreferrer"
						>
							{chunks}
						</HelperLink>
					),
				})}
			</Subtitle>
		</SectionStyled>
	);
};

const SftpStatus: FC<{ formProps: EditSftpFormProps }> = ({ formProps }) => {
	const partner = useDefinedPartner();
	const { data: latestUploads, loading: loadingSyncTime } = useEligibilityUploads({
		partnerId: partner.id,
		status: 'completed',
		pageIndex: 0,
		pageSize: 1,
	});
	const lastSyncDate = latestUploads?.eligibility_uploads[0]?.processing_start_at
		? new Date(latestUploads.eligibility_uploads[0].processing_start_at)
		: null;
	const statusEmailsErrorProps = formProps.bindWithErrorProps('email_recipients');
	return (
		<>
			<CellTitle>SFTP Status</CellTitle>
			<SectionStyled>
				<Subtitle>
					{loadingSyncTime
						? 'Loading status...'
						: lastSyncDate
						? `Last sync was ${lastSyncDate.toLocaleString()}.`
						: 'SFTP has not yet been synced.'}
				</Subtitle>
			</SectionStyled>
			<SectionStyled>
				<Subtitle>You can add multiple emails to receive notifications of SFTP errors by email.</Subtitle>
			</SectionStyled>
			<FormInput
				{...statusEmailsErrorProps}
				label="Email for SFTP notifications (comma separated for multiple)"
			/>
		</>
	);
};

const SftpIntegrationInner: FC<{ sftpConfig: SftpConfig }> = ({ sftpConfig }) => {
	const { formatMessage } = useIntl();
	const { formProps, hasChangedAny, hasTouchedAny } = useSftpForm(
		sftpConfig.email_recipients?.join(', ') ?? '',
	);
	const { getSftpSubmitData, showValidationErrors } = useSftpSubmitData(formProps);

	const [uploadSshKey, { loading }] = useUploadSshKey();
	const [addEmailRecipients, { loading: isLoading }] = useAddSftpEmailRecipients();

	const onSubmit = async (e: FormEvent): Promise<void> => {
		e.preventDefault();
		if (showValidationErrors()) {
			return;
		}
		const submitData = getSftpSubmitData();
		if (submitData.email_recipients) {
			addEmailRecipients({ email_recipients: submitData.email_recipients })
				.then(async () => {
					if (submitData.ssh_key) {
						await uploadSshKey({ sshKey: submitData.ssh_key });
					}
					formProps.resetAllDirtyStates();
				})
				.catch(() => {});
		}
	};

	const innerForm = (
		<>
			<CardStyled>
				<SftpStatus formProps={formProps} />
			</CardStyled>
			<CardStyled>
				<CellTitle>{formatMessage(messages.sftpTitle)}</CellTitle>
				<SftpAdmin />
				{<SshKey formProps={formProps} isConfigured={!!sftpConfig.sftp_username} />}
				{sftpConfig.sftp_username && <SftpDetails username={sftpConfig.sftp_username} />}
				<HelpSection />
			</CardStyled>
		</>
	);

	return (
		<form onSubmit={onSubmit}>
			{innerForm}
			<StickSaveButton
				isFormDirty={hasChangedAny}
				hasTouchedForm={hasTouchedAny}
				isLoading={loading || isLoading}
				dataTestId="create-edit-health-config-save-btn"
			>
				Save Configuration
			</StickSaveButton>
		</form>
	);
};

const SftpIntegration: FC = () => {
	const { data: sftpConfig, loading } = useSftpConfig();

	if (loading || !sftpConfig) {
		return <Loader color="gray1" />;
	}

	return <SftpIntegrationInner sftpConfig={sftpConfig} />;
};

export default SftpIntegration;
