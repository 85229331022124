import { defineMessages } from 'react-intl';

export default defineMessages({
	achPaymentMethodVerificationFailedError: {
		id: 'achPaymentMethodVerificationFailedError',
		defaultMessage: 'The payment verification has failed.',
		description: 'Error message when the ACH payment method verification has failed',
	},
	achPaymentMethodProcessingFailedError: {
		id: 'achPaymentMethodProcessingFailedError',
		defaultMessage: 'The payment processing has failed. Make sure there are enough funds.',
		description: 'Error message when the ACH payment method processing has failed',
	},
	getPaymentInfoError: {
		id: 'getPaymentInfoError',
		defaultMessage: 'An error occurred while fetching payment information.',
		description: 'Error message when an error occurs while fetching payment information',
	},
	achPaymentMethodProcessing: {
		id: 'achPaymentMethodProcessing',
		defaultMessage: 'Your payment has been verified and is now processing. This can take up to 5 days.',
		description: 'Message when the ACH payment method is processing',
	},
	achPaymentMethodVerifying: {
		id: 'achPaymentMethodVerifying',
		defaultMessage:
			'Your payment method is not yet verified. We estimate you will receive your micro-deposit(s) on {arrivalDate}. <link>Verify securely here.</link>',
		description: 'Message when the ACH payment method is verifying',
	},
	tryAgainOrChangePaymentMethod: {
		id: 'tryAgainOrChangePaymentMethod',
		defaultMessage:
			'{failureExplanation} Please <tryagain>try again</tryagain> or <changedetails>change payment details</changedetails>.',
		description: 'Message telling the user to try again or change their payment method on failure',
	},
	achProcessingTitle: {
		id: 'achModal.processingTitle',
		defaultMessage: 'Payment is processing',
		description: 'Title for the ACH modal when processing payment',
	},
	achProcessingBody: {
		id: 'achModal.processingBody',
		defaultMessage:
			'Your ACH payment method is processing. This typically takes 5 days.<newline></newline><newline></newline>You can still get set up and start using Calm while you wait. Please note that if your payment method fails, you and your employees will lose access to Calm until your payment method is updated.',
		description: 'First paragraph of the ACH modal body when processing payment',
	},
	achVerifyingTitle: {
		id: 'achModal.verifyingTitle',
		defaultMessage: 'Verify payment for full access to Calm',
		description: 'Title for the ACH modal when verifying bank account',
	},
	achVerifyingBody: {
		id: 'achModal.verifyingBody',
		defaultMessage:
			'Your ACH payment method is being verified. This typically takes 5 days, and will require action from you. <b>Please look out for instructions in the email sent by Stripe.</b><newline></newline><newline></newline>Once your payment method is verified and your payment is processed, your payment will be complete.<newline></newline><newline></newline>You can still get set up and start using Calm while you wait. Please note that if your payment method fails, you and your employees will lose access to Calm until your payment method is updated.',
		description: 'First paragraph of the ACH modal body when verifying bank account',
	},
	achSucceededTitle: {
		id: 'achModal.succeededTitle',
		defaultMessage: 'Your payment is verified and processed',
		description: 'Title for the ACH modal when payment has succeeded',
	},
	achSucceededBody: {
		id: 'achModal.succeededBody',
		defaultMessage:
			'Your payment method has been verified and your payment has been processed. Enjoy using Calm and the Partner Portal!',
		description: 'First paragraph of the ACH modal body when payment has succeeded',
	},
	achSucceededButtonText: {
		id: 'achModal.succeededButtonText',
		defaultMessage: 'Done',
		description: 'Text for button in ACH modal when payment has succeeded',
	},
	achButtonText: {
		id: 'achModal.button',
		defaultMessage: 'I understand',
		description: 'Text for the button in the ACH modal',
	},
});
